/**
 * 收款单相关打印变量
 */
const receiptVoucherPrintTempOptions = [
  {
    label: '收款单',
    value: 'all',
    children: [
      { label: '收款单', value: 1 },
      { label: '收款单--双表格', value: 2 }
    ]
  },
]
const receiptVoucherPrintTempJsonOptions = {
  // 郑州管城区配送中心
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 61.5,
          paperFooter: 387.49821428571425,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                right: 361.5,
                title: '批发延期收款单',
                bottom: 23,
                hCenter: 14.5,
                vCenter: 301.5,
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                field: 'createTime',
                right: 371.25,
                title: '开单日期',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 279.75,
                testData: 'JHC11001202303270004',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                field: 'printTime',
                right: 569.25,
                title: '打印时间',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 473.25,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                field: 'billNo',
                right: 183,
                title: '收款单号',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 98.25,
                testData: '01-大仓',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 46.5,
                height: 15,
                width: 183,
                field: 'usedPrepayAmt',
                right: 372.75,
                title: '合计预收款',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 281.25,
                testData: '999.00',
                formatter:
                  "function(value,options, templateData) {return (Number(options ) === 0 ? '0' : options)+ '元'}",
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 46.5,
                height: 15,
                width: 169.5,
                field: 'customerName',
                right: 184.5,
                title: '批发客户',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 99,
                testData: 'CGSS20230327000054',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 67.5,
                height: 60,
                width: 553.5,
                field: 'printLists',
                right: 570,
                bottom: 127.5,
                hCenter: 97.5,
                vCenter: 293.25,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '批发销货单号',
                      field: 'sourceNo',
                      checked: true,
                      columnId: 'sourceNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummaryText: '合计',
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '批发销货日期',
                      field: 'completeTime',
                      checked: true,
                      columnId: 'completeTime',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      formatter2:
                        "function(value,row,index, options){ return window.$dayjs(row.completeTime).format('YYYY-MM-DD HH:mm:ss') }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '延期收款日期',
                      field: 'printTime',
                      checked: true,
                      columnId: 'printTime',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      formatter2:
                        "function(value,row,index, options){ return window.$dayjs(Date.now()).format('YYYY-MM-DD') }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '延期应收金额',
                      field: 'receivableAmt',
                      checked: true,
                      columnId: 'receivableAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryAlign: 'right',
                      tableSummaryNumFormat: '2',
                    },
                    {
                      width: 100,
                      title: '收款金额',
                      field: 'receiveAmt',
                      checked: true,
                      columnId: 'receiveAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryAlign: 'right',
                      tableSummaryNumFormat: '2',
                    },
                    {
                      width: 100,
                      title: '含税进价',
                      field: 'costAmt',
                      checked: false,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 13.5,
                top: 127.5,
                height: 15,
                width: 114,
                right: 127.5,
                title: '业务：',
                bottom: 145.5,
                hCenter: 138,
                vCenter: 70.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 214.5,
                top: 127.5,
                height: 15,
                width: 127.5,
                right: 341.25,
                title: '财务：',
                bottom: 142.5,
                hCenter: 135,
                vCenter: 277.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 427.5,
                top: 129,
                height: 15,
                width: 138,
                right: 544.7499847412109,
                title: '制单',
                bottom: 169.7499961853027,
                hCenter: 162.2499961853027,
                vCenter: 486.99998474121094,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
    2: {
      "panels": [
        {
          "index": 0,
          "name": 1,
          "height": 141,
          "width": 210,
          "paperHeader": 61.5,
          "paperFooter": 392.5,
          "printElements": [
            {
              "options": {
                "left": 185,
                "top": 10,
                "height": 17,
                "width": 262.5,
                "right": 377.75001525878906,
                "title": "勤达商贸（寻甸）有限公司 收款单",
                "bottom": 25.50000762939453,
                "hCenter": 17.00000762939453,
                "vCenter": 284.00001525878906,
                "fontSize": 16.5,
                "hideTitle": true,
                "textAlign": "center",
                "fontWeight": "700",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false
              },
              "printElementType": {
                "title": "标题",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 189,
                "top": 27,
                "height": 15,
                "width": 183,
                "field": "createTime",
                "right": 371.25,
                "title": "开单日期",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 279.75,
                "testData": "JHC11001202303270004",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 376.5,
                "top": 27,
                "height": 15,
                "width": 190.5,
                "field": "printTime",
                "right": 569.25,
                "title": "打印时间",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 473.25,
                "testData": "2023-04-06",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 13.5,
                "top": 27,
                "height": 15,
                "width": 169.5,
                "field": "billNo",
                "right": 183,
                "title": "收款单号",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 98.25,
                "testData": "01-大仓",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 189,
                "top": 46.5,
                "height": 15,
                "width": 183,
                "field": "usedPrepayAmt",
                "right": 372.75,
                "title": "合计预收款",
                "bottom": 61.5,
                "hCenter": 54,
                "vCenter": 281.25,
                "testData": "999.00",
                "formatter": "function(value,options, templateData) {return (Number(options ) === 0 ? '0' : options)+ '元'}",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 13.5,
                "top": 46.5,
                "height": 15,
                "width": 169.5,
                "field": "customerName",
                "right": 184.5,
                "title": "批发客户",
                "bottom": 61.5,
                "hCenter": 54,
                "vCenter": 99,
                "testData": "CGSS20230327000054",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 372.5,
                "top": 47.5,
                "height": 15,
                "width": 183,
                "field": "remainPrepayAmt",
                "right": 556.0000305175781,
                "title": "预收款--余额",
                "bottom": 61.5,
                "hCenter": 54,
                "vCenter": 464.5000305175781,
                "testData": "999.00",
                "formatter": "function(value,options, templateData) {return (Number(options ) === 0 ? '0' : options)+ '元'}",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 13.5,
                "top": 67.5,
                "height": 55.5,
                "width": 553.5,
                "field": "printLists",
                "right": 570,
                "bottom": 127.5,
                "hCenter": 97.5,
                "vCenter": 293.25,
                "coordinateSync": false,
                "widthHeightSync": false,
                "groupFooterFormatter": "function(t,e){return\"这里自定义统计脚信息\";}",
                "tableHeaderBackground": "#ffffff",
                "columns": [
                  [
                    {
                      "width": 121.8071805447143,
                      "title": "批发销货单号",
                      "field": "sourceNo",
                      "checked": true,
                      "columnId": "sourceNo",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "left",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummaryText": "合计",
                      "tableSummary": ""
                    },
                    {
                      "width": 117.01739363490243,
                      "title": "批发销货日期",
                      "field": "completeTime",
                      "checked": true,
                      "columnId": "completeTime",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "formatter2": "function(value,row,index, options){ return window.$dayjs(row.completeTime).format('YYYY-MM-DD HH:mm:ss') }",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 114.81524813037468,
                      "title": "延期收款日期",
                      "field": "printTime",
                      "checked": true,
                      "columnId": "printTime",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "formatter2": "function(value,row,index, options){ return window.$dayjs(Date.now()).format('YYYY-MM-DD') }",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 120.84457766122536,
                      "title": "延期应收金额",
                      "field": "receivableAmt",
                      "checked": true,
                      "columnId": "receivableAmt",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": false,
                      "tableSummary": "sum",
                      "tableSummaryAlign": "center",
                      "tableSummaryNumFormat": "2"
                    },
                    {
                      "width": 79.01560002878321,
                      "title": "收款金额",
                      "field": "receiveAmt",
                      "checked": true,
                      "columnId": "receiveAmt",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "vAlign": "middle",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": false,
                      "tableSummary": "sum",
                      "tableSummaryAlign": "center",
                      "tableSummaryNumFormat": "2"
                    }
                  ]
                ]
              },
              "printElementType": {
                "title": "表格",
                "type": "table",
                "editable": true,
                "columnDisplayEditable": true,
                "columnDisplayIndexEditable": true,
                "columnTitleEditable": true,
                "columnResizable": true,
                "columnAlignEditable": true,
                "isEnableEditField": true,
                "isEnableContextMenu": true,
                "isEnableInsertRow": true,
                "isEnableDeleteRow": true,
                "isEnableInsertColumn": true,
                "isEnableDeleteColumn": true,
                "isEnableMergeCell": true
              }
            },
            {
              "options": {
                "left": 12.5,
                "top": 132.5,
                "height": 40.5,
                "width": 553.5,
                "field": "paymentItems",
                "right": 561.5000076293945,
                "bottom": 173.75001525878906,
                "hCenter": 153.50001525878906,
                "vCenter": 286.50000762939453,
                "coordinateSync": false,
                "widthHeightSync": false,
                "groupFooterFormatter": "function(e,t){return\"这里自定义统计脚信息\"}",
                "tableHeaderBackground": "#fcfcfc",
                "columns": [
                  [
                    {
                      "width": 184.5,
                      "title": "支付方式",
                      "field": "paymentTypeName",
                      "checked": true,
                      "columnId": "paymentTypeName",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 184.5,
                      "title": "支付金额",
                      "field": "paymentAmt",
                      "checked": true,
                      "columnId": "paymentAmt",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 184.5,
                      "title": "备注",
                      "field": "remark",
                      "checked": true,
                      "columnId": "remark",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "center",
                      "halign": "center",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    }
                  ]
                ]
              },
              "printElementType": {
                "title": "表格",
                "type": "table",
                "editable": true,
                "columnDisplayEditable": true,
                "columnDisplayIndexEditable": true,
                "columnTitleEditable": true,
                "columnResizable": true,
                "columnAlignEditable": true,
                "isEnableEditField": true,
                "isEnableContextMenu": true,
                "isEnableInsertRow": true,
                "isEnableDeleteRow": true,
                "isEnableInsertColumn": true,
                "isEnableDeleteColumn": true,
                "isEnableMergeCell": true
              }
            },
            {
              "options": {
                "left": 20,
                "top": 182.5,
                "height": 13.5,
                "width": 363,
                "field": "realPaymentAmt",
                "right": 140.00001525878906,
                "title": "收款金额（大写）",
                "bottom": 192,
                "hCenter": 187.125,
                "vCenter": 80.00001525878906,
                "fontSize": 12,
                "upperCase": "7",
                "fontFamily": "Microsoft YaHei",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 395,
                "top": 182.5,
                "height": 13.5,
                "width": 147,
                "field": "realPaymentAmt",
                "right": 541.0000076293945,
                "title": "收款金额",
                "bottom": 195.25000762939453,
                "hCenter": 188.50000762939453,
                "vCenter": 467.5000076293946,
                "fontSize": 12,
                "fontFamily": "Microsoft YaHei",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 165,
                "top": 200,
                "height": 15,
                "width": 127.5,
                "right": 312,
                "title": "财务：",
                "bottom": 226.5,
                "hCenter": 219,
                "vCenter": 248.25,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 370,
                "top": 200,
                "height": 15,
                "width": 138,
                "right": 511.5,
                "title": "制单",
                "bottom": 216.75,
                "hCenter": 209.25,
                "vCenter": 442.5,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 20,
                "top": 200,
                "height": 15,
                "width": 114,
                "right": 132.75,
                "title": "业务：",
                "bottom": 224.25,
                "hCenter": 216.75,
                "vCenter": 75.75,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            }
          ],
          "paperNumberLeft": 553.5,
          "paperNumberTop": 18,
          "paperNumberContinue": true,
          "orient": 1,
          "watermarkOptions": {}
        }
      ]
    }
  },
}

export default {
  // 收款单 -- 不同配送中心打印模板名
  receiptVoucherPrintTempOptions,
  // 收款单 -- 不同分公司打印模板名json
  receiptVoucherPrintTempJsonOptions,
}
